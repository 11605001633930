<template>
  <div class="service_details_container">
    <img class="title_image" src="../../assets/img_shipping.png" />
    <h4>START SHIPPING</h4>
    <div class="step_view_wrapper" style="display:none;">
      <div class="strip">
        <div class="strip_half" style="display:block"></div>
        <div class="strip_half"></div>
        <div class="circle">
          <div class="circle_labels">SERVICE OPTIONS</div>
        </div>
        <div class="circle two">
          <div class="circle_labels">SHIPMENT DETAILS</div>
        </div>
        <div class="circle three inactive">
          <div class="circle_labels">CONFIRM SHIPMENT</div>
        </div>
      </div>
    </div>

    <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
      <section style="display:none;">
        <h2>SERVICE DETAILS</h2>
        <div class="form_section1">
          <table class="tablce_cell_adj">
            <tr>
              <td>
                <div class="label_and_element_wrapper">
                  <label>
                    Item Reference
                    <span></span>
                  </label>
                  <input type="text" placeholder="Enter Character Limit 30" v-model="draft1.item_reference" :maxlength="max" :disabled="updateType == true"/>
                </div>
              </td>
              <td>
                <div class="label_and_element_wrapper">
                  <label>
                    Package
                    <span></span>
                  </label>
                  <input type="text" placeholder="Enter Character Limit 30" v-model="draft1.item_type" :maxlength="max" :disabled="updateType == true"/>
                </div>
              </td>
              <td>
                <div class="label_and_element_wrapper">
                  <label>
                    Weight(Kg)
                    <span></span>
                  </label>
                  <input type="text" placeholder="Enter Character Limit 5" v-model="draft1.item_weight" :maxlength="max1" :disabled="updateType == true"/>
                </div>
              </td>
              <td>
                <div class="label_and_element_wrapper">
                  <label>
                    Items
                    <span></span>
                  </label>
                  <input type="text" placeholder="Enter Character Limit 5" v-model="draft1.item_qty" :maxlength="max1" :disabled="updateType == true"/>
                </div>
              </td>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      L(m)
                      <span></span>
                    </label>
                    <!-- <input type="text" placeholder="Enter Character Limit 5" v-model="draft1.item_length" :maxlength="max1" :disabled="volume == 0 " /> -->

                        <input type="text" placeholder="Enter Character Limit 5"  :value="draft1.item_length" @change="updateLength" :maxlength="max1" :disabled="volume == 0 " v-if="!updateType"/>
                        <input type="text" placeholder="Enter Character Limit 5"  :value="draft1.item_length" @change="updateLength" :maxlength="max1" :disabled="updateType == true" v-else/>
                  </div>
                </td>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      W(m)
                      <span></span>
                    </label>
                    <input type="text" placeholder="Enter Character Limit 5" :value="draft1.item_width" @change="updateWidth" :maxlength="max1" :disabled="volume == 0 " v-if="!updateType"/>
                    <input type="text" placeholder="Enter Character Limit 5" :value="draft1.item_width" @change="updateWidth" :maxlength="max1" :disabled="updateType == true" v-else/>
                  </div>
                </td>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      H(m)
                      <span></span>
                    </label>
                    <input type="text" placeholder="Enter Character Limit 5" :value="draft1.item_height" @change="updateHeight" :maxlength="max1" :disabled="volume == 0 " v-if="!updateType"/>
                    <input type="text" placeholder="Enter Character Limit 5" :value="draft1.item_height" @change="updateHeight" :maxlength="max1" :disabled="updateType == true" v-else/>
                  </div>
                </td>
              <td>
                <div class="label_and_element_wrapper">
                  <label>
                    m3
                    <span></span>
                  </label>
                  <input type="text" placeholder="Enter Character Limit 5" v-model="draft1.item_m3" :maxlength="max1" :disabled="volume == 0 " v-if="!updateType"/>
                  <input type="text" placeholder="Enter Character Limit 5" v-model="draft1.item_m3" :maxlength="max1" :disabled="updateType == true" v-else/>
                </div>
              </td>
              <td>
                <div class="label_and_element_wrapper">
                  <label>
                    Cubic kg
                    <span></span>
                  </label>
                  <input type="text" placeholder="Enter Character Limit 5" v-model="draft1.item_cubic_kg" :maxlength="max1" :disabled="updateType == true"/>
                </div>
              </td>
              <td>
                <div class="label_and_element_wrapper">
                  <label>
                    Charge Unit
                    <span></span>
                  </label>
                  <input type="text" placeholder="Enter Character Limit 5" v-model="draft1.item_charge_unit" :maxlength="max1" :disabled="updateType == true"/>
                </div>
              </td>
              <td>
                <div class="label_and_element_wrapper">
                  <label>
                    DG
                    <span></span>
                  </label>
                  <input type="checkbox" v-model="draft1.item_is_dg" true-value="1" false-value="0" :disabled="is_dg == 0 " v-if="!updateTypeboolean"/>
                  <input type="checkbox" v-model="draft1.item_is_dg" true-value="1" false-value="0" :disabled="updateType == true" v-else/>
                </div>
              </td>
              <td>
                <!-- <div class="label_and_element_wrapper">
                  <label>
                    Food
                    <span></span>
                  </label>
                  <input type="checkbox" v-model="draft1.item_is_food" true-value="1" false-value="0" :disabled="is_food == 0 " v-if="!updateTypeboolean"/>
                  <input type="checkbox" v-model="draft1.item_is_food" true-value="1" false-value="0" :disabled="updateType == true" v-else/>
                </div> -->
              </td>
            </tr>
          </table>
        </div>
      </section>
      <section style="display:none;">
        <h2>DESCRIPTION OF GOODS</h2>
        <div class="form_section1">
          <table>
            <tr>
              <td>
                <!-- <ValidationProvider name="goods" rules="required|max:60" v-slot="{ errors }" slim> -->
                <div class="label_and_element_wrapper">
                  <label>
                    Enter the name of the goods
                  </label>
                  <textarea placeholder="Enter Character Limit 70" v-model="draft1.goods_description" :maxlength="max3" :disabled="updateType == true"></textarea>
                </div>
                <!-- </ValidationProvider> -->
              </td>
              <td>
                <!-- <ValidationProvider name="remarks" rules="required|max:60" v-slot="{ errors }" slim> -->
                <div class="label_and_element_wrapper">
                  <label>
                    Enter any remarks
                  </label>
                  <textarea placeholder="Enter Character Limit 70" v-model="draft1.remarks" :maxlength="max3" :disabled="updateType == true"></textarea>
                </div>
                <!-- </ValidationProvider> -->
              </td>
            </tr>
          </table>
        </div>
      </section>

      <section v-if="delivery_service == 'International' " style="display:none;">
        <h2>INTERNATIONAL CHARGES</h2>
        <div class="form_section1">
          <table>
            <tr>
              <td class="series_td">
                <div class="label_and_element_wrapper">
                  <label>
                    Declared Value
                  </label>
                </div>
              </td>
              <td>
                <div class="label_and_element_wrapper">
                  <input type="text" placeholder="Enter Character Limit 30" v-model="draft1.declared_value" :maxlength="max"  :disabled="updateType == true"/>
                </div>
              </td>
              <td class="series_td">
                <div class="label_and_element_wrapper">
                  <label>
                    Insurance Value
                  </label>
                </div>
              </td>
              <td>
                <div class="label_and_element_wrapper">
                  <input type="text" placeholder="Enter Character Limit 30" v-model="draft1.insured_value" :maxlength="max"  :disabled="updateType == true"/>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </section>

      <section style="display:none;">
        <h2>SCHEDULED PICKUP</h2>
        <div class="form_section1">
          <table>
            <tr>
              <td class="series_td">
                <div class="label_and_element_wrapper">
                  <label>
                    Location of Packages
                  </label>
                </div>
              </td>
              <td>
                <div class="label_and_element_wrapper">
                  <input type="text" placeholder="Enter Character Limit 30" v-model="draft1.package_location" :maxlength="max"  :disabled="updateType == true"/>
                </div>
              </td>
              <td class="series_td">
                <div class="label_and_element_wrapper">
                  <label>
                    My Package Will Be Ready By
                    <span>*</span>
                  </label>
                </div>
              </td>
              <td>
                <div class="label_and_element_wrapper">
                  <flat-pickr
                  :config="configTime"
                  placeholder="Select Time"
                  name="birthtime" v-model="draft1.pickup_time" :disabled="updateType == true"
                ></flat-pickr>
                </div>
              </td>
            </tr>

            <tr>
              <td class="series_td">
                <div class="label_and_element_wrapper">
                  <label>
                    Pickup Date
                    <span>*</span>
                  </label>
                </div>
              </td>
              <td>
                <div class="label_and_element_wrapper">
                  <flat-pickr
                    class="date_for_pos_fixed"
                    v-model="draft1.pickup_date"
                    :config="configDate"
                    placeholder="Date"
                    name="birthdate" :disabled="updateType == true"
                  ></flat-pickr>
                </div>
              </td>
              <td class="series_td">
                <div class="label_and_element_wrapper">
                  <label>
                    Office Close At
                    <span>*</span>
                  </label>
                </div>
              </td>
              <td>
                <div class="label_and_element_wrapper">
                  <flat-pickr
                    v-model="draft1.office_close_time"
                    :config="configTime"
                    placeholder="Select Time"
                    name="birthtime" :disabled="updateType == true"
                  ></flat-pickr>
                </div>
              </td>
            </tr>

            <tr>
              <td class="series_td">
                <div class="label_and_element_wrapper">
                  <label>
                    Pickup Instructions
                    <br />(Upto 70 characters)
                    <span></span>
                  </label>
                </div>
              </td>
              <td>
                <div class="label_and_element_wrapper">
                  <textarea placeholder="Description here Character Limit 70" v-model="draft1.pickup_instructions" :maxlength="max3"  :disabled="updateType == true"></textarea>
                </div>
              </td>
              <td class="series_td"></td>
              <td></td>
            </tr>
          </table>
        </div>
      </section>





    <section v-show="updateTypeboolean" id="update_container">
      <h2>UPDATE BOOKING DETAILS</h2>
      <div class="form_section1">
        <table>
          <tr>
            <td class="series_td">
              <div class="label_and_element_wrapper">
                <label>
                  No. of parcels / douments
                  <span>*</span>
                </label>
              </div>
            </td>
            <td>
              <ValidationProvider name="item qty" rules="required|max:30" v-slot="{ errors }" slim>
                <div class="label_and_element_wrapper">
                  <input type="text" placeholder="no.of parcels"  v-model="updatedraft.item_qty" :class="errors[0] ? 'warning-border':''" :maxlength="max"  />
                </div>
              </ValidationProvider>
            </td>
            <td class="series_td">
              <div class="label_and_element_wrapper">
                <label>
                  Weight
                  <span>*</span>
                </label>
              </div>
            </td>
            <td>
              <ValidationProvider name="item weight" rules="required|max:30" v-slot="{ errors }" slim>
                <div class="label_and_element_wrapper">
                  <input type="text" placeholder="weight"  v-model="updatedraft.item_weight" :class="errors[0] ? 'warning-border':''" :maxlength="max"  />
                </div>
              </ValidationProvider>
            </td>
          </tr>
          <tr>
            <td class="series_td">
              <div class="label_and_element_wrapper">
                <label>
                  Dispatched Date
                  <span>*</span>
                </label>
              </div>
            </td>
            <td>
              <ValidationProvider name="date" rules="required|max:30" v-slot="{ errors }" slim>
                <div class="label_and_element_wrapper">
                  <flat-pickr
                    :class="errors[0] ? 'warning-border':''"
                    v-model="updatedraft.dispatched_date"
                    :config="configDate"
                    placeholder="Date"
                    name="birthdate2"
                  ></flat-pickr>
                </div>
              </ValidationProvider>
            </td>
            <td class="series_td">
              <div class="label_and_element_wrapper">
                <label>
                  Reg Mode of Dispatch
                  <span>*</span>
                </label>
              </div>
            </td>
            <td>
              <ValidationProvider name="mode of dispatch" rules="required|max:30" v-slot="{ errors }" slim>
                <div class="label_and_element_wrapper">
                  <!-- <select v-model="updatedraft.mode_of_dispatch" :class="errors[0] ? 'warning-border':''">
                    <option value="" disabled>Select mode of dispatch</option>
                    <option v-for="(dispatch,i) in dispatches" :key="'dispatches'+i" :value="dispatch.id">{{dispatch.name}}</option>
                  </select> -->
                  <input type="text" placeholder="mode of dispatch"  v-model="updatedraft.mode_of_dispatch" :class="errors[0] ? 'warning-border':''" :maxlength="max"  />
                </div>
              </ValidationProvider>
            </td>
          </tr>
          <tr>
            <td class="series_td">
              <div class="label_and_element_wrapper">
                <label>
                  Dispatch Company
                  <span>*</span>
                </label>
              </div>
            </td>
            <td>
              <ValidationProvider name="delivery provider" rules="required|max:30" v-slot="{ errors }" slim>
                <div class="label_and_element_wrapper">
                  <input type="text" placeholder="Dispatch Company"  v-model="updatedraft.delivery_provider" :class="errors[0] ? 'warning-border':''" :maxlength="max"  />
                </div>
              </ValidationProvider>
            </td>
            <td class="series_td">
              <div class="label_and_element_wrapper">
                <label>
                  Airway Bill
                  <span>*</span>
                </label>
              </div>
            </td>
            <td>
              <ValidationProvider name="airway_bill" rules="required|max:30" v-slot="{ errors }" slim>
                <div class="label_and_element_wrapper">
                  <input type="text" placeholder="Airway Bill"  :class="errors[0] ? 'warning-border':''"  v-model="updatedraft.airway_bill" :maxlength="max"  />
                </div>
              </ValidationProvider>
            </td>
          </tr>
          <tr>
            <td class="series_td">
              <div class="label_and_element_wrapper">
                <label>
                  Cost
                  <span>*</span>
                </label>
              </div>
            </td>
            <td>
              <ValidationProvider name="cost" rules="required|max:30" v-slot="{ errors }" slim>
                <div class="label_and_element_wrapper">
                  <input type="text" placeholder="Cost" :class="errors[0] ? 'warning-border':''" v-model="updatedraft.cost" :maxlength="max"  />
                </div>
              </ValidationProvider>
            </td>
            <td class="series_td">
              <div class="label_and_element_wrapper">
                <label>
                  Other Charges
                  <span></span>
                </label>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <input type="text" placeholder="Other Charges"  v-model="updatedraft.other_charges"/>
              </div>
            </td>
          </tr>
          <tr>
            <td class="series_td">
              <div class="label_and_element_wrapper">
                <label>
                  Reg Remarks
                  <!-- <span>*</span> -->
                </label>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <textarea placeholder="Enter the remarks" v-model="updatedraft.reg_remarks"></textarea>
              </div>
            </td>
            <td class="series_td"></td>
            <td></td>
          </tr>
        </table>
      </div>
    </section>


    </ValidationObserver>





    <section class="centered">
      <!-- <router-link to="/pages/ServiceDetails" class="link_bt bt_cancel">Previous</router-link> -->
      <a class="link_bt bt_save" @click="previous" v-if="!updateTypeboolean">Previous</a>

      <a class="link_bt bt_cancel"  @click="$router.push('/pages/'+returnPage)">Cancel</a>
      <!-- <router-link to="/pages/ServiceConfirm" class="link_bt bt_save">Save and Next</router-link> -->
      <a class="link_bt bt_save" @click="submit" >{{save_btn.value}}</a>
    </section>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";
export default {
  name: "ServiceUpdate",
  components: {
    flatPickr,

  },
  data() {
    return {
      updateType:true,
      updateTypeboolean:"",
      editTypeboolean:"",
      date:"",
      max: 30,
      max1: 5,
      max2: 60,
      max3: 70,
      // enableTime: true,
      configTime: {
        enableTime: true,
        noCalendar: true
      },
      configDate: {
        format: "d-m-Y",
        altFormat: "d-m-Y",
        altInput: true
      },
      delivery_service:"International",
      type : "",
      pageType:"",
      weight : "",
      qty : "",
      length : "",
      width : "",
      height : "",
      m3 : "",
      cubic_kg : "",
      charge_unit : "",
      is_dg : "",
      is_food : "",
      volume:"",
      dispatches:[],
      save_btn:{
        value:"Save and Next"
      },
      draft1:{
        item_reference : "NA",
        item_type : "",
        item_weight : 0,
        item_qty : 1,
        item_length : 0,
        item_width : 0,
        item_height : 0,
        item_m3 : 0,
        item_cubic_kg : 0,
        item_charge_unit : 0,
        item_is_dg : 0,
        item_is_food : 0,
        goods_description : "",
        remarks : "",
        package_location : "",
        pickup_date : this.moment(new Date()).format('YYYY-MM-DD'),
        pickup_instructions : "",
        pickup_time : this.moment(new Date()).format("HH:mm:ss"),
        office_close_time : this.moment(new Date()).format("HH:mm:ss"),
        declared_value : "",
        insured_value : "",
      },
      updatedraft:{
        item_qty:"",
        item_weight:"",
        dispatched_date:moment(new Date()).format('YYYY-MM-DD'),
        mode_of_dispatch:"",
        delivery_provider:"",
        cost:"",
        airway_bill:"",
        other_charges:"",
        reg_remarks:""
      }
    };
  },
  created(){
    console.log(this.$route.params.pageType);
    this.pageType=this.$route.params.pageType;
    if(this.pageType=='Courier')
    {
      this.returnPage='ViewCourierPending';
    }
    else if(this.pageType=='Mailroom')
    {
      this.returnPage='ViewPending';
    }
    else
    {
      this.returnPage='ViewEncodePending';
    }


    if(this.$route.params.type == "update"){
      this.updateTypeboolean = this.$route.params.type;
      this.updateType= true;
      this.save_btn.value = "UPDATE";
    }else{
      this.updateType= false;
    }
    this.draft1.office_close_time = "18:00:00";
    this.draft1.pickup_date = this.moment(new Date()).format('YYYY-MM-DD');
    this.draft1.pickup_time =  this.moment(new Date()).format("HH:mm:ss");

    this.axios.get("/api/draft/"+this.$route.params.id + "/service")
    .then(response => {
      console.log(response);
        this.delivery_service = response.data.msg.delivery_service;
      // console.log(this.delivery_service);
    })
    .catch(error =>{
      console.log(error);
    });

    if(this.$route.params.id){
      this.axios.get("/api/draft/"+this.$route.params.id)
      .then(response => {
      console.log(response);

        let goods_id = response.data.draft.type_of_good;
        if(goods_id){
          this.axios.get("/api/goods/"+goods_id)
          .then(response => {
            console.log(response);
            this.type = response.data.goods.type;
            this.weight = response.data.goods.weight;
            this.length = response.data.goods.length;
            this.width = response.data.goods.width;
            this.height = response.data.goods.height;
            this.m3 = (this.draft1.item_length)*(this.draft1.item_width)*(this.draft1.item_height);
            this.item_cubic_kg = response.data.goods.cubic_kg;
            this.is_dg = response.data.goods.is_dg;
            this.is_food = response.data.goods.is_food;
            this.volume = response.data.goods.volume;
          })
          .catch(error => {
            console.log(error);
          });
        }
         

          this.draft1.item_type=response.data.draft.goods.type ;
          
          // console.log('josepheena');
          // console.log(response.data.draft.goods.type);

          this.draft1.item_weight=response.data.draft.item_weight ? response.data.draft.item_weight : response.data.draft.goods.weight;

          this.draft1.item_length=response.data.draft.item_length ? response.data.draft.item_length : response.data.draft.goods.length;

          this.draft1.item_width= response.data.draft.item_width ? response.data.draft.item_width : response.data.draft.goods.width;

          this.draft1.item_height=response.data.draft.item_height ? response.data.draft.item_height : response.data.draft.goods.height;

          this.draft1.item_m3=response.data.draft.item_m3 ? response.data.draft.item_m3 : response.data.draft.goods.m3;

          this.draft1.item_cubic_kg= response.data.draft.item_cubic_kg ? response.data.draft.item_cubic_kg : response.data.draft.goods.cubic_kg;

          if(response.data.draft.item_is_dg != null){
            this.draft1.item_is_dg=response.data.draft.item_is_dg ;
          } else {
            this.draft1.item_is_dg= response.data.draft.goods.is_dg;
          }

          if(response.data.draft.item_is_food != null){
            this.draft1.item_is_food=response.data.draft.item_is_food;
          } else {
            this.draft1.item_is_food= response.data.draft.goods.is_food;
          }


          this.draft1.item_reference=response.data.draft.item_reference ? response.data.draft.item_reference : this.draft1.item_reference;

          this.draft1.item_qty=response.data.draft.item_qty;

          this.draft1.item_charge_unit=response.data.draft.item_charge_unit ? response.data.draft.item_charge_unit : 0;

          this.draft1.goods_description=response.data.draft.goods_description;

          this.draft1.remarks=response.data.draft.remarks;

          this.draft1.package_location=response.data.draft.package_location;

          this.draft1.pickup_instructions=response.data.draft.pickup_instructions;
         
          this.draft1.pickup_date= response.data.draft.pickup_date ? moment(response.data.draft.pickup_date).format('YYYY-MM-DD')  : this.draft1.pickup_date;

          this.draft1.pickup_time=response.data.draft.pickup_time ? response.data.draft.pickup_time : this.draft1.pickup_time;

          this.draft1.office_close_time=response.data.draft.office_close_time ? response.data.draft.office_close_time : this.draft1.office_close_time;

          this.draft1.declared_value=response.data.draft.declared_value;

          this.draft1.insured_value=response.data.draft.insured_value;

          this.updatedraft.item_qty=response.data.draft.item_qty;
          this.updatedraft.item_weight=response.data.draft.item_weight;

           


          if(response.data.draft.dispatched_date != null){
            this.updatedraft.dispatched_date=response.data.draft.dispatched_date;
          } else {
            this.updatedraft.dispatched_date=moment(new Date()).format('YYYY-MM-DD');
          }


          

          this.updatedraft.mode_of_dispatch=response.data.draft.mode;
          // this.updatedraft.mode_of_dispatch=response.data.draft.mode_of_dispatch;
          this.updatedraft.delivery_provider=response.data.draft.delivery_provider;
          this.updatedraft.cost=response.data.draft.cost;


          this.updatedraft.airway_bill=response.data.draft.airway_bill;
          this.updatedraft.other_charges=response.data.draft.other_charges;
          this.updatedraft.reg_remarks=response.data.draft.reg_remarks;
      })
      .catch(error =>{
        console.log(error);
      });
    } 
    this.fetchDispatch();
  },
  mounted(){
    if(this.$route.params.type == "update"){
      this.$scrollTo("#update_container",500,{
        offset:-120
      });
    }
  },
  methods:{
    previous(){
       console.log(this.$route.params.type);
      if(this.$route.params.type != undefined){
        this.editTypeboolean = this.$route.params.type;
        this.$router.push("/pages/ServiceDetails/"+this.$route.params.id+"/"+this.editTypeboolean);
      } else {
        this.$router.push("/pages/ServiceDetails/"+this.$route.params.id);
      }
      
    },
    updateLength(event) {
      this.draft1.item_length = event.target.value;
      this.draft1.item_m3 =  this.draft1.item_length * this.draft1.item_width * this.draft1.item_height;
    },
    updateWidth(event) {
      this.draft1.item_width = event.target.value;
      this.draft1.item_m3 =  this.draft1.item_length * this.draft1.item_width * this.draft1.item_height;
    },
    updateHeight(event) {
      this.draft1.item_height = event.target.value;
      this.draft1.item_m3 =  this.draft1.item_length * this.draft1.item_width * this.draft1.item_height;
    },
    fetchDispatch(){
      this.axios.get("/api/dispatch/")
      .then(response => {
        console.log(response);
          this.dispatches = response.data.mode_of_dispatch;
      })
      .catch(error =>{
          console.log(error);
      });
    },
    submit(){

       console.log(this.$route.params.type);
        this.$refs.observer.validate();
      if(this.updateTypeboolean == "update"){
        this.axios.put("/api/courier/domestic/"+this.$route.params.id,this.updatedraft)
        .then(response => {
        console.log(response);
          this.toast.success(response.data.msg);
          this.$router.push("/pages/"+this.returnPage);
          this.save_btn.value = "UPDATE";
        })
        .catch(error =>{
          this.save_btn.value = "UPDATE";
          console.log(error);
        });
      } else {
        this.axios.put("/api/draft/"+this.$route.params.id + "/second",this.draft1)
        .then(response => {
        console.log(response);
          this.toast.success(response.data.msg);
          if(this.$route.params.type != undefined){
            this.editTypeboolean = this.$route.params.type;
            this.$router.push("/pages/ServiceConfirm/"+this.$route.params.id+"/"+this.editTypeboolean);
          } else {
            this.$router.push("/pages/ServiceConfirm/"+this.$route.params.id);
          }
          this.save_btn.value = "SAVE AND NEXT";
        })
        .catch(error =>{
          console.log(error);
        });
      }
    }
  },
};
</script>
<style scoped>
.service_details_container {
  padding: 20px;
}
.service_details_container > section {
  padding: 20px 0 0 0;
}
.service_details_container > section h2 {
  font-size: 20px;
  padding: 10px 0 10px 0;
  border-bottom: 4px solid #666;
  display: inline-block;
}
.title_image {
  display: block;
  margin: auto;
}
h4 {
  font-weight: 600;
  text-align: center;
  padding: 30px 0;
  font-size: 18px;
}
.step_view_wrapper {
  width: 100%;
  height: 140px;
  background: #181818;
  padding: 20px 80px;
  position: relative;
}
.step_view_wrapper .strip {
  width: calc(100% - 160px);
  height: 10px;
  background: #666;
  position: absolute;
  top: 50%;
  line-height: 0;
}
.step_view_wrapper .strip_half {
  height: 10px;
  width: 50%;
  background: #06A5ED;
  display: inline-block;
  display: none;
}
.step_view_wrapper .circle {
  width: 30px;
  height: 30px;
  background: #000;
  border: 5px solid #06A5ED;
  border-radius: 100px;
  position: absolute;
  left: -10px;
  top: -10px;
}
.step_view_wrapper .circle.inactive {
  background: #ffffff;
  border-color: #999;
}
.step_view_wrapper .circle.two {
  left: 50%;
  margin-left: -10px;
}
.step_view_wrapper .circle.three {
  left: 100%;
  margin-left: -10px;
}
.step_view_wrapper .circle_labels {
  font-size: 14px;
  text-align: center;
  color: #fff;
  position: absolute;
  width: 120px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  top: 40px;
  left: -60px;
}

.label_and_element_wrapper.sp {
  width: 33%;
}
.lone_chk_label {
  padding: 20px;
  display: block;
}
.lone_chk_label input {
  display: inline-block;
  margin-right: 10px;
}
.service_logo {
  width: 300px;
  height: auto;
  padding: 20px 0;
}
.service_logo img {
  display: block;
  width: 100%;
  height: auto;
}
td.series_td {
  width: 220px;
}
table.tablce_cell_adj tr td {
  vertical-align: top;
  padding: 0;
}
table.tablce_cell_adj tr td input[type="checkbox"] {
  width: 20px;
  height: 20px;
  display: inline-block;
}
table.tablce_cell_adj tr td:nth-child(-n + 2) {
  width: auto;
}
table.tablce_cell_adj tr td:nth-child(n + 3):nth-child(-n + 10) {
  width: 100px;
}
table.tablce_cell_adj tr td:nth-child(n + 3):nth-child(n + 11) {
  width: 60px;
}
</style>